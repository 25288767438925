import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import InternalLink from "../components/internal_link"

const IndexPage = () => (
  <Layout>
    <SEO title="Index" />
    <div style={{overflow: "auto"}}>
      <div style={{
        marginBottom: "100px",
        float: "left"
      }}>
        Autor • Regisseur • guter Koch
      </div>
      <div style={{ width: `50%`, marginBottom: `1.45rem`, marginLeft: `auto`, marginRight: `0%`, float: "right"}}>
        <Image />
      </div>
    </div>

      <InternalLink destination="/vita" label="VITA"/>
      <br></br>
      <InternalLink destination="/filmografie" label="FILMOGRAFIE"/>    
      <br></br>
      <InternalLink destination="/kontakt" label="KONTAKT"/>    
   
  </Layout>
)

export default IndexPage
